import React from 'react';
import { BulkUploadForOrganizationPage } from '../BulkUploadForOrganizationPage';
import { UserBulkActivation } from '../UserBulkActivation';
import { UsersUploadModal } from './UsersUploadModal';

export function UserBulkUpload() {
  const header = (
    <>
      <h1>Bulk User Upload</h1>
      <p>
        You can upload new or existing users. If a user email exists, they will
        be assigned a new project role.
      </p>
      <p>
        No invitation emails will be sent. It means that you can safely
        re-upload the same file.
      </p>
    </>
  );
  return (
    <>
      <BulkUploadForOrganizationPage
        header={header}
        ModalComponent={UsersUploadModal}
      />
      <UserBulkActivation />
    </>
  );
}
